import React from "react"
import styled from "styled-components"
import CssBaseline from "@mui/material/CssBaseline"

import GlobalStyle from "@/components/GlobalStyle"
import { Outlet } from "react-router-dom"

import {
  BannerBlock,
  OnlySemantic,
  BannerBlockSpacer,
  BannerImage,
  AlbumCoverWrapper,
  AlbumCover,
  CoverPlayOverlay,
  AlbumCoverImage,
} from "./PresentationLayout.styled"
import { useIsMobile } from "@/hooks/useWidthSize"

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PresentationLayout: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <BannerBlock>
          <OnlySemantic>MetaPerverse by MindPort</OnlySemantic>
          <BannerBlockSpacer isMobile={isMobile} />
          <BannerImage isMobile={isMobile} />
          <AlbumCoverWrapper>
            <AlbumCover isMobile={isMobile}>
              <CoverPlayOverlay />
              <AlbumCoverImage src="./assets/img/large.jpg" />
            </AlbumCover>
          </AlbumCoverWrapper>
          <BannerBlockSpacer isMobile={isMobile} />
        </BannerBlock>
        <Outlet />
      </AppContent>
    </Root>
  )
}

export default PresentationLayout
